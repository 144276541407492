import { css } from "@emotion/css";
import React from "react";
import { CopyBlock, dracula, github } from "react-code-blocks";

export const CodeBlockComponent = ({ text, language, style }: { text: string; style?: Record<string, unknown>; language: string }) => {
    return (
        <div className={css`

            & > div > span:first-child {
                padding-left: 0 !important;
            }

        `}>
        <CopyBlock
            text={text}
          
            language={language}
            customStyle={{
                borderRadius: "10px",
                // border: "1px solid #979797",
                background: "#101010",
                padding: "16px 16px",
                overflowX: "auto",
                lineHeight: "1.5",
                ...style,
            }}
            theme={{
                ...dracula,
                backgroundColor: "#101010",
            }}
            wrapLongLines={false}
        />
        </div>
    );
};
